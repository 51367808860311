import { storeUserLoggedInLocalStorage } from '../utils/general'
import { getStorage } from '../utils/storage'

export default function ({ $auth, redirect, route, store, next }) {
  if (
    window.location.hostname === 'auth.qontak.net' ||
    window.location.hostname === 'auth.qontak.com'
  ) {
    redirect(302, process.env.BASE_URL)
    return
  }

  const data = {
    auth: route.query.code,
    is_direct: true,
    is_sso: true,
    sso: true,
  }

  function signOutFromSSO() {
    store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
      status: false,
      message:
        'You will be redirected to launchpad. If you need to help, contact our support team.',
    })
    setTimeout(() => {
      redirect(
        `${process.env.SSO_URL}/sign_out?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}`
      )
    }, 3000)
  }

  function redirectLinktoCRM() {
    store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
      status: false,
      message:
        'You will be redirected to your CRM page to continue. If you need to upgrade your account or renew your Omnichannel subscription, contact our support team.',
    })
    setTimeout(() => {
      redirect(
        `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${process.env.CRM_URL}/sso-callback`
      )
    }, 3000)
  }

  async function oldLogin() {
    // Old method login (without has_crm / has_chat response)
    try {
      await $auth.loginWith('hub', {
        data,
      })
      await $auth.fetchUser()
      storeUserLoggedInLocalStorage($auth.user.email)

      // Check role account
      if ($auth.user.role === 'super_admin') {
        store.commit('preferences/UPDATE_FIRST_INIT', false)
        redirect(302, '/')
        // eslint-disable-next-line no-useless-return
        return
      } else {
        // If not superAdmin then check the billing config
        store.dispatch('preferences/getBillingInfoConfig').then((res) => {
          if (['grace', 'active', 'freeze'].includes(res.status)) {
            store.commit('preferences/UPDATE_FIRST_INIT', false)
            redirect(302, '/')
            // eslint-disable-next-line no-useless-return
            return
          } else {
            // If user billing status false then checking user on CRM
            try {
              store
                .dispatch('users/getUserByEmail', {
                  email: $auth.user.email,
                  is_crm_check: true,
                })
                .then(async () => {
                  // Delete session on chatpanel before
                  const data = {
                    token: $auth.getToken('hub').split(' ')[1],
                  }
                  await $auth.logout(data)
                  // Redirect to CRM
                  window.location.replace(
                    `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${process.env.CRM_URL}/sso-callback`
                  )
                })
                .catch(() => {
                  // If user on crm not found, then redirect back to chatpanel
                  store.commit('preferences/UPDATE_FIRST_INIT', false)
                  redirect(302, '/')
                  // eslint-disable-next-line no-useless-return
                  return
                })
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err)
            }
          }
        })
      }
    } catch (e) {
      signOutFromSSO()
    }
  }

  async function doSSOValidation() {
    await store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
      status: true,
    })
    // FLAG Variable
    let isUnifiedSignUpMekari
    await store
      .dispatch(
        'preferences/getFeatureFlag',
        'check_account_existence_on_login'
      )
      .then((res) => {
        isUnifiedSignUpMekari = res?.state
      })

    if (isUnifiedSignUpMekari) {
      try {
        await $auth
          .loginWith('hub', {
            data,
          })
          .then(async ({ data: res }) => {
            // Check role account
            if ($auth.user.role === 'super_admin') {
              store.commit('preferences/UPDATE_FIRST_INIT', false)
              store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
                status: true,
              })
              redirect(302, '/')
              // eslint-disable-next-line no-useless-return
              return
            }
            if (res?.data?.has_chat) {
              await $auth.fetchUser()
              storeUserLoggedInLocalStorage($auth.user.email)

              if ($auth.user.role === 'super_admin') {
                store.commit('preferences/UPDATE_FIRST_INIT', false)
                store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
                  status: true,
                })
                redirect(302, '/')
                return
              }

              store
                .dispatch('preferences/getBillingInfoConfig')
                .then(async (billingConfig) => {
                  if (
                    ['grace', 'active', 'freeze'].includes(billingConfig.status)
                  ) {
                    store.commit('preferences/UPDATE_FIRST_INIT', false)
                    store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
                      status: true,
                    })
                    redirect(302, '/')
                    // eslint-disable-next-line no-useless-return
                    return
                  } else if (res?.data?.has_crm) {
                    const tokenUser = {
                      token: $auth.getToken('hub').split(' ')[1],
                    }
                    await $auth.logout(tokenUser)
                    redirectLinktoCRM()
                  } else {
                    // If user on crm not found, then redirect back to chatpanel
                    store.commit('preferences/UPDATE_FIRST_INIT', false)
                    store.commit('preferences/SET_SUCCESS_CALLBACK_SSO', {
                      status: true,
                    })
                    redirect(302, '/')
                    // eslint-disable-next-line no-useless-return
                    return
                  }
                })
            } else if (res?.data?.has_crm) {
              // Only have CRM account
              redirectLinktoCRM()
            } else if (!res?.data.has_chat && !res?.data.has_crm) {
              const referralCode = getStorage('referral_code_signup')
              // Redirect to unified_sign up
              await $auth.reset()
              next(
                `/sign_up?code=${res?.data.access_token}&referral_code=${referralCode}`
              )
            }
          })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        signOutFromSSO()
      }
    } else {
      oldLogin()
    }
  }

  // Init function
  if (data.auth) {
    doSSOValidation()
  } else {
    signOutFromSSO()
  }
}
