import { datadogRum } from '@datadog/browser-rum'

const initiateDatadogRum = ({ $config, $state }) => {
  if (process.env.BASE_URL === 'https://chat.qontak.com') {
    datadogRum.init({
      applicationId: process.env.DATADOG_RUM_APP_ID,
      clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'qontak-chat-hub',
      env: 'qontak',
      version: $config.appversion,
      sessionSampleRate: 1,
      sessionReplaySampleRate: 1,
      trackUserInteractions: true,
      allowedTracingUrls: [process.env.BASE_URL],
    })

    // Disabled due to a reason
    // datadogRum.startSessionReplayRecording()
  }
}

export default initiateDatadogRum
