import Vue from 'vue'

const tryCatch = require('try-catch')
Vue.prototype.$try = function (objKey, replace) {
  if (objKey === null || objKey === undefined) {
    return replace
  }
  if (tryCatch(objKey, replace).error) {
    return replace
  } else {
    return objKey
  }
}
