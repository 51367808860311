export default async function ({ redirect, route, store, $auth }) {
  const config = await store.dispatch('layouts/getConfiguration')
  const isUsingSSO =
    config?.sso?.status &&
    (config?.sso_staging_enabled
      ? config.sso_staging_enabled.includes(window.location.origin)
      : true)
  const isUsingParameter = config?.sso?.is_using_parameter
  const user = $auth.$state

  if (!user.loggedIn) {
    if (route.query.is_launchpad && config.is_launchpad_login) {
      redirect(
        302,
        `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${process.env.QONTAK_LAUNCHPAD_URL}/sso-callback`
      )
    }

    if (isUsingSSO || (!isUsingParameter && route.query.sso)) {
      redirect(
        302,
        `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${window.location.origin}/sso-callback`
      )
    }
  }
}
