// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

// import store from '../store'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.HUB_FCM_API_KEY,
  authDomain: process.env.HUB_FCM_AUTH_DOMAIN,
  databaseURL: process.env.HUB_FCM_DATABASE_URL,
  projectId: process.env.HUB_FCM_PROJECT_ID,
  storageBucket: process.env.HUB_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.HUB_FCM_MESSAGING_SENDER_ID,
  appId: process.env.HUB_FCM_APP_ID,
  measurementId: process.env.HUB_FCM_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)

navigator.serviceWorker.register(
  `${process.env.BASE_URL}/firebase-messaging-sw.js`
)

// getToken(messaging, { vapidKey: process.env.HUB_FCM_VAPID_KEY }).then((currentToken) => {
//   console.log('masuk')
//   if (currentToken) {
//     console.log('current token:', currentToken)
//   } else {
//     console.log('cannot get token')
//   }
// })

// function requestPermission() {
//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       const app = initializeApp(firebaseConfig)
//       const messaging = getMessaging(app)
//       getToken(messaging, { vapidKey: process.env.HUB_FCM_VAPID_KEY }).then((currentToken) => {
//         if (currentToken) {
//           console.log('current token:', currentToken)
//         } else {
//           console.log('cannot get token')
//         }
//       })
//       console.log(true)
//     } else {
//       console.log(false)
//     }
//   })
// }
// requestPermission()

// let roomId = ''

// onBackgroundMessage(messaging, function(payload) {
//   if (payload.notification) {
//     // Customize notification here
//     let notificationTitle = 'Hub - Notification'
//     let notificationOptions = {
//       body: 'Multi Channel Communication Platform',
//       icon: '/qontak-chat-logo.png',
//       vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
//       sound: '/incoming_message.mp3'
//     }
//     if (payload.notification === undefined) {
//       notificationTitle = payload.data.title
//       notificationOptions = {
//         body: payload.data.body,
//         icon: '/qontak-chat-logo.png',
//         vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
//         sound: '/incoming_message.mp3'
//       }
//     } else {
//       notificationTitle = payload.notification.title
//       notificationOptions = {
//         body: payload.notification.body,
//         icon: '/qontak-chat-logo.png',
//         vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
//         sound: '/incoming_message.mp3'
//       }
//     }
//     self.clients.matchAll({ includeUncontrolled: true, type: 'window' }).then((all) =>
//       all.forEach((client) => {
//         client.postMessage(payload.data)
//       })
//     )
//     roomId = payload.data.room_id
//     return self.registration.showNotification(notificationTitle, notificationOptions)
//   }
// })
// // [END background_handler]
// self.addEventListener('notificationclick', function(event) {
//   self.clients.matchAll({ includeUncontrolled: true, type: 'window' }).then((all) =>
//     all.forEach((client) => {
//       client.postMessage({
//         mode: 'onclick',
//         room_id: roomId
//       })
//     })
//   )
// })
