import Vue from 'vue'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import 'prismjs'
import 'prismjs/themes/prism.css'

Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
Vue.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    link: {
      ADD_ATTR: ['target'],
    },
  },
})
