export default function (context) {
  context.$axios.create({
    headers: {
      common: {
        Accept: 'application/json, text/plain, */*',
      },
    },
  })

  // interceptor request
  context.$axios.onRequest((config) => {
    if (context.app.$auth.loggedIn) {
      const cookieObj = new URLSearchParams(
        document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')
      )
      const token = cookieObj.get('qontak._token.hub')
      context.$axios.setToken(token.split(' ')[1], 'bearer')
    }
  })

  // interceptor response
  context.$axios.onResponse((_response) => {
    // do some logic
  })

  // interceptor error
  context.$axios.onError((_error) => {
    // do some logic
  })
}
