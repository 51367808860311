import Vue from 'vue'
import mixpanel from 'mixpanel-browser'

export default ({ app }) => {
  const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN

  mixpanel.init(MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV !== 'production',
    ignore_dnt: process.env.NODE_ENV !== 'production',
  })

  mixpanel.register({
    'App Version': app.$config.appversion,
  })

  Vue.use(mixpanel)
}
