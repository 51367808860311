import resource from '@/api/resource'

export default function ({ route }) {
  return new Promise((resolve, reject) => {
    resource.get('/api/core/v1/client_configs/config').then((response) => {
      const { v2_feature_links: V2FeatureLinks } = response.data.data
      if (V2FeatureLinks.includes(route.path)) {
        window.location.href = `${process.env.HUB_URL}${route.path}`
      }
      resolve()
    })
  })
}
