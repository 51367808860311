import { setStorage } from '../utils/storage'

export default function ({ redirect, route }) {
  if (route.query.lang) {
    setStorage('sign_up_lang', route.query.lang, {
      domain: process.env.HOST_URL,
    })
  }
  if (route.query.referral_code) {
    setStorage('referral_code_signup', route.query.referral_code, {
      domain: process.env.HOST_URL,
    })
  }
  if (route.query.pic_validation) {
    setStorage('pic_validation', route.query.pic_validation, {
      domain: process.env.HOST_URL,
    })
  }
  if (
    window.location.hostname === 'auth.qontak.net' ||
    window.location.hostname === 'auth.qontak.com'
  ) {
    redirect(302, process.env.BASE_URL)
  }
}
