import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { min, max } from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    // eslint-disable-next-line import/namespace
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

// source: many kind of built-in rules by vee validate
// https://vee-validate.logaretm.com/v3/guide/rules.html#rules

extend('min', min)
extend('max', max)

extend('after30m', {
  getMessage: () => 'This must be set at least 30 minutes from now',
  validate: (value) => {
    const thirtyMinutesAfterToday = new Date(Date.now() + 1000 * (30 * 60))
    const isPrevious = new Date(value) < thirtyMinutesAfterToday
    return !isPrevious
  },
})

extend('isUrl', {
  validate: (value) => {
    const formatted = value.toLowerCase()
    const isSpace = /\s/g.test(formatted)
    const isHttp = formatted.includes('http://')
    const isHttps = formatted.includes('https://')
    const isDomainDotCom = formatted.includes('.com')
    const isDomainDotCo = formatted.includes('.co')
    const isDomainDotCoId = formatted.includes('.co.id')
    const isDomainDotNet = formatted.includes('.net')
    const isDomainDotId = formatted.includes('.id')
    return !!(
      (isHttp || isHttps) &&
      (isDomainDotCom ||
        isDomainDotCo ||
        isDomainDotCoId ||
        isDomainDotNet ||
        isDomainDotId) &&
      !isSpace
    )
  },
  message: '{_field_} must be a valid url',
})

extend('authentication_expiry_time', {
  getMessage: () => 'You must input a valid number between 2 and 90',
  validate: (value) => {
    if (isNaN(value)) return false
    else value = parseInt(value)
    if (Math.floor(value) < 1) return false
    return value >= 1 && value <= 90
  },
})

extend('is_auto_resolve_passed', {
  getMessage: () => 'You must be input one or more',
  validate: (value, [secondValue]) => {
    const periodOne = Number(value)
    const periodTwo = Number(secondValue)
    const totalPeriod = periodOne + periodTwo
    if (totalPeriod < 1) {
      return false
    } else {
      return true
    }
  },
})

extend('parameter_below_100', {
  getMessage: (_field, args) =>
    'Total weight must be 100%. You must add (' +
    100 -
    JSON.parse(args).reduce((n, { weight }) => n + parseInt(weight), 0) +
    ')% weight fot it.',
  validate: (_value, args) => {
    return (
      JSON.parse(args).reduce((n, { weight }) => n + parseInt(weight), 0) >= 100
    )
  },
})

extend('parameter_above_100', {
  getMessage: (field, args) => {
    return 'Total weight must be 100%. There is an excess of 100%.'
  },
  validate: (_value, args) => {
    return (
      JSON.parse(args).reduce((n, { weight }) => n + parseInt(weight), 0) <= 100
    )
  },
})

extend('required_rules_form_condition_sla', {
  getMessage: () => 'Field required form_condition_sla',
  validate: (value, secondValue) => {
    const val = secondValue[0]

    if (val.name && val.condition && val.target) {
      return true
    }
    return false
  },
})
